import {Injectable} from '@angular/core';
import { Account } from '../../shared/model/account.model';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    constructor() {
    }

    public clearLocalStorage(): void {
        localStorage.clear();
    }

    public storeAccountSession(account: Account): void {
        localStorage.setItem('account', JSON.stringify(account));
    }

    public storeSession(key: string, params: any) {
        sessionStorage.setItem(key, JSON.stringify(params));
    }

    public getSession(key: string): any {
        // @ts-ignore
        return JSON.parse(sessionStorage.getItem(key));
    }

    public clearSession(key: string): any {
        sessionStorage.removeItem(key);
    }

    public getAccountSession(): Account {
        const account = localStorage.getItem('account');
        return account ? JSON.parse(account) : null
    }

    public getTokenSession(): string {
        const accountSession = this.getAccountSession();

        if (accountSession) {
            return accountSession.token;
        }
        return "";
    }

    public removeAccountSession() {
        localStorage.removeItem('user');
    }

}
