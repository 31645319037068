import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';

import { SessionService } from './session.service';
import { DatePipe } from "@angular/common";
import { HttpResponseSuccess } from './model/http-response.success';
import { DOCUMENT_DOWNLOAD_API } from '../../shared/constant/endpoint.const';

@Injectable({
    providedIn: 'root',
})
export class BaseService {

    public updateTable: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private httpClient: HttpClient,
        private sessionService: SessionService
    ) {
    }

    private DOWNLOAD_FILE_API = '';

    public generateHttpParam(param: any): HttpParams {
        let result = new HttpParams();

        if (param) {
            Object.keys(param).forEach((key) => {
                if (
                    param[key] !== null &&
                    param[key] !== undefined &&
                    param[key] !== ''
                ) {
                    result = result.set(key, param[key]);
                }
            });
        }

        return result;
    }

    public getData(
        url: string,
        requestParam?: any,
        requestHeader?: any
    ): Observable<any> {
        const headers = new HttpHeaders({
            ...requestHeader,
        });

        const params = requestParam
            ? this.generateHttpParam(requestParam)
            : undefined;

        return this.httpClient.get(url, { headers, params });
    }

    public getDataWithToken<T>(
        url: string,
        requestParam?: any,
        requestHeader?: any
    ): Observable<HttpResponseSuccess<T>> {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession(),
            ...requestHeader,
        });

        const params = requestParam
            ? this.generateHttpParam(requestParam)
            : undefined;

        return this.httpClient.get<HttpResponseSuccess<T>>(url, { headers, params });
    }

    public postData<T>(url: string, requestBody: any): Observable<HttpResponseSuccess<T>> {
        const headers = new HttpHeaders({});
        return this.httpClient.post<HttpResponseSuccess<T>>(url, requestBody, { headers })
    }

    public postDataWithToken<T>(url: string, requestBody: any): Observable<HttpResponseSuccess<T>> {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession(),
        });
        return this.httpClient.post<HttpResponseSuccess<T>>(url, requestBody, { headers })
    }

    public postDataWithSelfToken<T>(url: string, token: string | null, requestBody: any): Observable<HttpResponseSuccess<T>> {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + token,
        });
        return this.httpClient.post<HttpResponseSuccess<T>>(url, requestBody, { headers });
    }

    public putDataNoAuth<T>(
        url: string,
        requestBody: any,
        requestParam?: any
    ): Observable<HttpResponseSuccess<T>> {
        const headers = new HttpHeaders({});
        const params = requestParam
            ? this.generateHttpParam(requestParam)
            : undefined;

        return this.httpClient.put<HttpResponseSuccess<T>>(url, requestBody, { headers: headers, params });
    }

    public putDataWithToken(
        url: string,
        requestBody: any,
        requestParam?: any
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession(),
        });
        const params = requestParam
            ? this.generateHttpParam(requestParam)
            : undefined;

        return this.httpClient.put(url, requestBody, { headers: headers, params });
    }


    public postFileMultipart<T>(url: string, formData: FormData): Observable<HttpResponseSuccess<T>> {
        // const headers = new HttpHeaders();
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession(),
        });
        return this.httpClient.post<HttpResponseSuccess<T>>(url, formData, { headers });
    }

    private arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = [].slice.call(new Uint8Array(buffer));

        bytes.forEach((b) => {
            binary += String.fromCharCode(b)
        });

        return btoa(binary);
    }


    public download(url: string): Observable<string> {
        const requestBody = { documentUrl: url };

        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession(),
        });

        return this.httpClient.post(DOCUMENT_DOWNLOAD_API, requestBody, { headers, responseType: 'arraybuffer' }).pipe(
            map((resp: any) => {
                let base64Flag = 'data:image/jpeg;base64,';
                if (url.toLowerCase().includes('.pdf')) {
                    base64Flag = 'data:application/pdf;base64,';
                }

                const imageStr = this.arrayBufferToBase64(resp);
                const base64Image = base64Flag + imageStr;

                return base64Image;
            })
        )
    }

    public downloadDocumentCustomAPI(url: string, requestBody: any): Observable<HttpResponse<ArrayBuffer>> {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession()
        });

        return this.httpClient.post(url, requestBody, { headers, observe: 'response', responseType: 'arraybuffer' });
    }

    public downloadDocumentCustomAPIUsingGET(url: string, requestParam?: any): Observable<HttpResponse<ArrayBuffer>> {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession()
        });

        const params = requestParam
            ? this.generateHttpParam(requestParam)
            : undefined;

        return this.httpClient.get(url, { headers, observe: 'response', responseType: 'arraybuffer' , params});
    }

    public downloadAsByte(url: string): Observable<string> {
        const requestBody = { documentUrl: url };

        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession(),
        });

        return this.httpClient.post(DOCUMENT_DOWNLOAD_API, requestBody, { headers, responseType: 'arraybuffer' }).pipe(
            map((resp: any) => {
                return resp;
            })
        )
    }


    public deleteDataWithToken<T>(url: string): Observable<HttpResponseSuccess<T>> {
        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + this.sessionService.getTokenSession(),
        });
        return this.httpClient.delete<HttpResponseSuccess<T>>(url, { headers })
    }

}
