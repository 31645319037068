import { Injectable, inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TOAST_SUCCESS, TOAST_ALERT, TOAST_WARNING } from '../constant/toast.config';

@Injectable({
  providedIn: 'root'
})
export class NgxToastrService {
  toastr = inject(ToastrService);


  success(message: string): void {
    this.toastr.success(message, '', TOAST_SUCCESS)
  }

  error(message: string): void {
    this.toastr.error(message, '', TOAST_ALERT);
  }

  warning(message: string): void {
    this.toastr.warning(message, '', TOAST_WARNING)
  }

}
