export const TOAST_SUCCESS = {
	toastClass:'app-toastr toastr-success ngx-toastr',
	closeButton: true
}

export const TOAST_WARNING = {
	toastClass:'app-toastr toastr-warning ngx-toastr',
	closeButton: true
}

export const TOAST_ALERT = {
	toastClass:'app-toastr toastr-alert ngx-toastr',
	closeButton: true
}

export const TOAST_ALERT_CAPITALIZE = {
	...TOAST_ALERT,
	toastClass:'toastr-alert ngx-toastr capitalize',
}
